<template>
  <div>
    <canvas ref="canvas"></canvas>
    <img
      v-if="gameStatus === 2 && isExplosionShown"
      src="@/assets/bomb.gif"
      alt="Explosion"
      class="explosion-gif"
    />
    <!-- <img src="@/assets/bomb.gif" alt="Explosion" class="explosion-gif" /> -->
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  reactive,
  toRefs,
} from 'vue';
import axios from 'axios';
import { decryptData, getQueryParams } from '@/utils/tools';
import { showToast } from 'vant';
import planeImageSrc from '@/assets/plane.png';

export default defineComponent({
  name: 'AviatorGame',
  setup(props, { emit }) {
    const canvas = ref<HTMLCanvasElement | null>(null);
    const ctx = ref<CanvasRenderingContext2D | null>(null);
    const planeImage = ref<HTMLImageElement | null>(null);
    let intervalId: number | null = null;
    let canvasWidth = window.innerWidth;
    let canvasHeight = 220;
    const devicePixelRatio = window.devicePixelRatio || 1;
    const scale = canvasWidth / 400;
    let acceleration = 0.25; // 加速度
    const maxFlightWidth = canvasWidth;
    const planeWidth = 90 * scale; // 飞机大小
    const planeHeight = 40 * scale; // 飞机高度
    const state = reactive({
      gameId: null as number | null,
      userToken: '' as string | null,
      userId: '' as string | null,
      deviceId: '' as string | null,
      source: '' as string | null,
      startTime: '' as any | null,
      endTime: '' as any | null,
      currentTime: Date.now(),
      prizeNum: 0,
      currentPrizeNum: 0,
      newPrizeNum: 0,
      gameStatus: 0,
      flightTime: 0,
      isExplosionShown: false,
      apiUrl: '',
    });

    const calculateCurveY = (x: number) => {
      return (
        canvasHeight - Math.pow(x * 0.06, 2) * (acceleration * 1.5) * scale
      );
    };

    const smoothIncreasePrizeNum = () => {
      const startTime = Date.now();
      const duration = 500;
      const initialPrizeNum = state.currentPrizeNum; // 当前奖励数字
      const targetPrizeNum = state.prizeNum; // 目标奖励数字

      const increase = () => {
        const elapsedTime = Date.now() - startTime; // 已经过的时间
        const progress = Math.min(elapsedTime / duration, 1); // 计算进度

        // 计算当前奖励数字，逐渐增加
        state.currentPrizeNum = Math.floor(
          initialPrizeNum + (targetPrizeNum - initialPrizeNum) * progress ** 2,
        ); // 使用 Math.floor() 确保是整数

        // 如果还没有达到目标，继续请求动画帧
        if (progress < 1) {
          requestAnimationFrame(increase);
        } else {
          // console.log("最终奖励数字:", state.currentPrizeNum);
        }
      };

      requestAnimationFrame(increase); // 启动动画
    };

    const drawCurve = (x: number) => {
      if (ctx.value) {
        ctx.value.clearRect(0, 0, canvasWidth, canvasHeight); // 清空画布
        ctx.value.beginPath();
        ctx.value.moveTo(0, canvasHeight); // 从左下角开始

        // 绘制起飞曲线，终点根据飞机的 X 坐标
        const maxX = Math.min(x, maxFlightWidth); // 确保不超过最大宽度
        for (let i = 0; i <= maxX; i++) {
          const curveY = calculateCurveY(i); // 使用计算函数计算曲线上的 y 值
          ctx.value.lineTo(i * scale, curveY);
        }

        // 绘制曲线的边框
        ctx.value.strokeStyle = 'red';
        ctx.value.lineWidth = 3;
        ctx.value.stroke(); // 只为曲线绘制边框

        // 填充底部区域
        ctx.value.lineTo(maxX * scale, canvasHeight); // 到达右下角
        ctx.value.lineTo(0, canvasHeight); // 返回左下角
        ctx.value.closePath();
        ctx.value.fillStyle = 'rgba(255, 0, 0, 0.3)'; // 半透明红色填充
        ctx.value.fill(); // 只填充，不描边

        // 绘制飞机图像
        if (planeImage.value) {
          let planeY = calculateCurveY(x);

          // 快到达终点上下飞
          if (isMidpointReached) {
            planeY += Math.sin(Date.now() / 300) * hoverAmplitude; // 上下移动
          }

          ctx.value.drawImage(
            planeImage.value,
            x * scale - planeWidth / 2 + 10,
            planeY - planeHeight / 2 - 32,
            planeWidth,
            planeHeight,
          );
        }

        let flightTime = 0;
        if (state.gameStatus !== 2 && state.startTime !== 0) {
          flightTime = Math.max(0, Date.now() - state.startTime);
        }
        ctx.value.fillStyle = '#fff';
        ctx.value.font = `bold ${20 * scale}px "Poppins", "Arial", sans-serif`; // 加粗
        const flightTimeText = `飞行时长：${(flightTime / 1000).toFixed(2)} 秒`;

        // 画布居中
        const flightTimeTextWidth = ctx.value.measureText(flightTimeText).width;
        const flightTimeTextX = (canvasWidth - flightTimeTextWidth) / 2;
        const flightTimeTextY = 22; // y 坐标

        ctx.value.fillText(flightTimeText, flightTimeTextX, flightTimeTextY); // 绘制飞行时长

        ctx.value.fillStyle = '#fff';
        ctx.value.font = `bold ${18 * scale}px "Poppins", "Arial", sans-serif`;
        const text = `奖励SVIP时长：${state.currentPrizeNum}小时`;

        const textWidth = ctx.value.measureText(text).width;

        const textX = (canvasWidth - textWidth) / 2;
        const textY = canvasHeight - 5;

        ctx.value.fillText(text, textX, textY);
      }
    };

    const showEndGameToast = () => {
      showToast({
        message: '游戏已结束，请等待下一回合。',
        position: 'top',
      });
    };

    const midpointX = canvasWidth * 0.85;
    let currentX = 30;

    const flightSpeed = 5; // 飞机的飞行速度
    const hoverAmplitude = 15; // 上下飞动的幅度
    let isMidpointReached = false; // 浮动点

    const animate = () => {
      const currentTime = Date.now();
      if (state.gameStatus === 1) {
        // const progress =
        //   (currentTime - state.startTime) / (state.endTime - state.startTime);

        // currentX = progress * canvasWidth; // 更新当前 X 坐标
        currentX += flightSpeed;

        if (currentX >= midpointX) {
          currentX = midpointX;
          isMidpointReached = true;
        }
        isEndGameToastShown = false;
        state.isExplosionShown = false;
        drawCurve(currentX);
        requestAnimationFrame(animate);
      } else if (state.gameStatus === 0) {
        currentX = 30;
        isMidpointReached = false;
        drawCurve(currentX);
        console.log('等待游戏开始...');
        state.currentPrizeNum = 0;
        isEndGameToastShown = false;
        state.isExplosionShown = false;
        setTimeout(animate, 1000);
      } else if (state.gameStatus === 2 && !isEndGameToastShown) {
        state.isExplosionShown = true;
        currentX = -999;
        drawCurve(currentX);
        state.currentPrizeNum = 0;
        state.prizeNum = 0;
        isMidpointReached = false;
        showEndGameToast();
        isEndGameToastShown = true; // 设置标志，避免重复提示
        setTimeout(() => {
          state.isExplosionShown = false;
          currentX = 30;
          drawCurve(currentX);
        }, 1450);
        console.log('游戏已结束啦啦啦啦啦啦');
      } else if (currentTime > state.endTime && !isEndGameToastShown) {
        currentX = 30;
        drawCurve(currentX);
        // showEndGameToast();
        state.currentPrizeNum = 0;
        state.prizeNum = 0;
        isMidpointReached = false;
        isEndGameToastShown = true; // 设置标志，避免重复提示
        state.isExplosionShown = true;
        console.log('重置111');
      }
    };

    animate();

    const startPolling = () => {
      fetchFlightTimes();
      intervalId = window.setInterval(fetchFlightTimes, 500);
    };

    const stopPolling = () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    const showToastMessage = (message: string) => {
      showToast({
        message,
        position: 'top',
      });
    };

    const { token, user_id, did, source } = getQueryParams(
      'token',
      'user_id',
      'did',
      'source',
    );
    const headers = {
      userid: user_id,
      Token: token,
      deviceid: did,
      source: source,
      'Content-Type': 'application/json',
    };

    let isEndGameToastShown = false;

    const fetchFlightTimes = async () => {
      const url = state.apiUrl + '/api/v2/flightGame/getFlightInfo';
      const requestData = {
        id: state.gameStatus === 2 ? 0 : state.gameId,
      };
      const headers = {
        userid: state.userId,
        Token: state.userToken,
        deviceid: state.deviceId,
        source: state.source,
        'Content-Type': 'application/json',
      };

      try {
        const res_temp = await sendRequest(url, requestData, headers);
        const res = handleResponse(res_temp);
        const responseData = res.data;
        // 确保状态分阶段更新
        state.newPrizeNum = responseData.prize_num || 0;
        const newGameStatus = responseData.status;

        state.startTime = responseData.start_flight_time || 0;
        state.endTime = responseData.end_flight_time || 0;
        state.gameId = responseData.id || 0;
        state.gameStatus = newGameStatus;

        // 在状态变化后发出事件
        emit('updateGameState', state.gameStatus);

        if (state.gameStatus === 2) {
          state.prizeNum = 0;
          state.currentPrizeNum = 0;
          state.newPrizeNum = 0;
        } else if (state.newPrizeNum > state.currentPrizeNum) {
          state.prizeNum = state.newPrizeNum;
          smoothIncreasePrizeNum();
        } else {
          console.log('奖励数字没有增加');
        }
        // 确保最后调用动画
        animate();
      } catch (error) {
        console.error('无法获取起飞时间', error);
      }
    };

    const startFlying = async () => {
      const myUrl = state.apiUrl + '/api/v2/flightGame/bet';
      try {
        const res_temp = await sendRequest(myUrl, {}, headers);
        const res = handleResponse(res_temp);

        if (res && res.code === 0) {
          showToastMessage(res.msg);
          emit('getGameConfig');
        } else {
          showToastMessage(res_temp.msg);
        }
      } catch (error) {
        console.error('Error in startFlying:', error);
      }
    };

    const sendRequest = async (url: string, data: object, headers: object) => {
      try {
        const response = await axios.post(url, data, { headers });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleResponse = (res_temp: any) => {
      if (res_temp.newcode === '1') {
        const res = decryptData(
          res_temp.newdata,
          'eDWowITDNUKgND6PfQfA3OMTJ0nm640L',
          '################',
        );
        return res;
      } else {
        return res_temp;
      }
    };

    onMounted(() => {
      if (canvas.value) {
        canvas.value.width = canvasWidth * devicePixelRatio;
        canvas.value.height = canvasHeight * devicePixelRatio;
        canvas.value.style.width = `${canvasWidth - 60}px`;
        canvas.value.style.height = `${canvasHeight}px`;

        ctx.value = canvas.value.getContext('2d');
        if (ctx.value) {
          ctx.value.scale(devicePixelRatio, devicePixelRatio);
        }

        planeImage.value = new Image();
        planeImage.value.src = planeImageSrc;
        planeImage.value.onload = () => {
          startPolling();
        };

        planeImage.value.onerror = () => {
          console.error('Failed to load plane image');
        };

        const { token, user_id, did, source } = getQueryParams(
          'token',
          'user_id',
          'did',
          'source',
        );
        state.userToken = token;
        state.userId = user_id;
        state.deviceId = did;
        state.source = source;
      }
    });

    onUnmounted(() => {
      stopPolling();
    });

    return {
      ...toRefs(state),
      canvas,
      startFlying,
    };
  },
});
</script>

<style scoped>
canvas {
  display: block;
  margin: 0 auto;
}
.explosion-gif {
  position: absolute;
  top: 10px;
  right: -15px;
  width: 120px;
  height: 120px;
}
</style>
