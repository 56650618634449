import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/zfdm.png'
import _imports_1 from '@/assets/bg.gif'
import _imports_2 from '@/assets/btn1.png'
import _imports_3 from '@/assets/amount_icon.png'
import _imports_4 from '@/assets/history_icon.png'


const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "aviato-game" }
const _hoisted_3 = { class: "btn" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "tab" }
const _hoisted_6 = { class: "tab-title" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "tab-content"
}
const _hoisted_9 = {
  key: 1,
  class: "hb-content"
}
const _hoisted_10 = { class: "hb-c-desc" }
const _hoisted_11 = { class: "attrs" }
const _hoisted_12 = {
  style: {"width":"170px","font-size":"12px"},
  class: "dt"
}
const _hoisted_13 = {
  style: {"width":"10px","font-size":"12px"},
  class: "dd"
}
const _hoisted_14 = {
  style: {"width":"10px","font-size":"12px"},
  class: "dt"
}
const _hoisted_15 = {
  style: {"width":"150px","font-size":"12px"},
  class: "dd"
}
const _hoisted_16 = { class: "amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AviatorGame = _resolveComponent("AviatorGame")!
  const _component_van_popover = _resolveComponent("van-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-logo" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })
    ], -1)),
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "bg" }, [
      _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AviatorGame, {
        onUpdateGameState: _ctx.handleGameStateUpdate,
        onGetGameConfig: _ctx.getGameConfig,
        ref: "aviatorGame"
      }, null, 8, ["onUpdateGameState", "onGetGameConfig"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.gameStatus === 0 ? _ctx.starFlight() : null), ["stop"])),
        src: 
          _ctx.gameStatus === 0
            ? require('@/assets/btn2.png')
            : require('@/assets/btn2_disable.png')
        ,
        alt: "开始飞行"
      }, null, 8, _hoisted_4),
      _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleEndGame && _ctx.handleEndGame(...args))),
        src: _imports_2,
        alt: "跳伞"
      }),
      _createElementVNode("span", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.starFlight && _ctx.starFlight(...args))),
        class: "amount"
      }, _toDisplayString(_ctx.gameConfig.flight_bet_price), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.id,
            onClick: _withModifiers(($event: any) => (_ctx.tabsChange(index)), ["stop"]),
            class: _normalizeClass({ active: _ctx.currIndex === index })
          }, _toDisplayString(item?.name), 11, _hoisted_7))
        }), 128))
      ]),
      (_ctx.currIndex === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[5] || (_cache[5] = [
            _createElementVNode("p", null, "1.点击开始飞行,然后等待回合开始", -1),
            _createElementVNode("p", null, "2.留意幸运专机,您获得的SVIP时长会在屏幕中央显示", -1),
            _createElementVNode("p", null, "3.在专机飞走前跳伞,SVIP就账了。最高可获得永久SVIP", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.currIndex === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("ul", _hoisted_11, [
                _cache[6] || (_cache[6] = _createStaticVNode("<li class=\"li1\" data-v-794fa587><span class=\"dt\" data-v-794fa587>游戏时间</span><span class=\"dd\" data-v-794fa587>飞行时间</span><span class=\"dd\" data-v-794fa587>金币</span><span class=\"dd\" data-v-794fa587>奖励</span></li>", 1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gameRecordList, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: "li2",
                    key: item.id
                  }, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(item.create_time), 1),
                    _createElementVNode("span", _hoisted_13, _toDisplayString(item.flight_duration) + "s", 1),
                    _createElementVNode("span", _hoisted_14, "-" + _toDisplayString(item.bet_price), 1),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(item.prize_num) + "小时", 1)
                  ]))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", null, [
        _createTextVNode(" 金币余额: " + _toDisplayString(_ctx.gameConfig?.amount) + " ", 1),
        _cache[7] || (_cache[7] = _createElementVNode("img", {
          src: _imports_3,
          alt: "金币余额"
        }, null, -1))
      ]),
      _createVNode(_component_van_popover, {
        show: _ctx.showPopover,
        "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showPopover) = $event)),
        theme: "dark",
        class: "history-popover",
        "actions-direction": "left-start"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.getHistoryList && _ctx.getHistoryList(...args))),
            style: {"display":"flex","justify-content":"space-between","align-items":"center","color":"#fff","font-size":"14px"}
          }, _cache[8] || (_cache[8] = [
            _createTextVNode(" 回合历史"),
            _createElementVNode("img", {
              src: _imports_4,
              alt: "金币余额"
            }, null, -1)
          ]))
        ]),
        default: _withCtx(() => [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.historyList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item) + "s", 1))
            }), 128))
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("p", null, "记录最近30个回合的飞行时间", -1))
        ]),
        _: 1
      }, 8, ["show"])
    ])
  ]))
}