import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/bomb.gif'


const _hoisted_1 = { ref: "canvas" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "Explosion",
  class: "explosion-gif"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("canvas", _hoisted_1, null, 512),
    (_ctx.gameStatus === 2 && _ctx.isExplosionShown)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}