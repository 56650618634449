import CryptoJS from 'crypto-js';

export const getQueryParams = (
  ...params: string[]
): Record<string, string | null> => {
  const url = new URL(window.location.href);
  const urlParams = new URLSearchParams(url.search);
  const result: Record<string, string | null> = {};
  params.forEach((param) => {
    result[param] = urlParams.get(param);
  });

  return result;
};

export const decryptData = (
  encryptedData: string,
  keyString: string,
  ivString: string,
) => {
  const key = CryptoJS.MD5(keyString);
  const iv = CryptoJS.MD5(ivString);
  const decrypt = CryptoJS.AES.decrypt(encryptedData, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  const result_base64 = CryptoJS.enc.Base64.stringify(decrypt);
  const result_str = window.atob(result_base64);
  return JSON.parse(decodeURIComponent(escape(result_str)));
};
