<template>
  <div class="home">
    <div class="text-logo">
      <img src="@/assets/zfdm.png" alt="" />
    </div>
    <div class="bg">
      <img src="@/assets/bg.gif" alt="" />
    </div>
    <div class="aviato-game">
      <AviatorGame
        @updateGameState="handleGameStateUpdate"
        @getGameConfig="getGameConfig"
        ref="aviatorGame"
      />
    </div>
    <div class="btn">
      <img
        @click.stop="gameStatus === 0 ? starFlight() : null"
        :src="
          gameStatus === 0
            ? require('@/assets/btn2.png')
            : require('@/assets/btn2_disable.png')
        "
        alt="开始飞行"
      />
      <img @click="handleEndGame" src="@/assets/btn1.png" alt="跳伞" />
      <span @click="starFlight" class="amount">{{
        gameConfig.flight_bet_price
      }}</span>
    </div>
    <div class="tab">
      <div class="tab-title">
        <div
          v-for="(item, index) in tabList"
          :key="item.id"
          @click.stop="tabsChange(index)"
          :class="{ active: currIndex === index }"
        >
          {{ item?.name }}
        </div>
      </div>
      <div class="tab-content" v-if="currIndex === 0">
        <p>1.点击开始飞行,然后等待回合开始</p>
        <p>2.留意幸运专机,您获得的SVIP时长会在屏幕中央显示</p>
        <p>3.在专机飞走前跳伞,SVIP就账了。最高可获得永久SVIP</p>
      </div>
      <div class="hb-content" v-if="currIndex === 1">
        <div class="hb-c-desc">
          <ul class="attrs">
            <li class="li1">
              <span class="dt">游戏时间</span>
              <span class="dd">飞行时间</span>
              <span class="dd">金币</span>
              <span class="dd">奖励</span>
            </li>
            <li class="li2" v-for="item in gameRecordList" :key="item.id">
              <span style="width: 170px; font-size: 12px" class="dt">{{
                item.create_time
              }}</span>
              <span style="width: 10px; font-size: 12px" class="dd"
                >{{ item.flight_duration }}s</span
              >
              <span style="width: 10px; font-size: 12px" class="dt"
                >-{{ item.bet_price }}</span
              >
              <span style="width: 150px; font-size: 12px" class="dd"
                >{{ item.prize_num }}小时</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="amount">
      <div>
        金币余额: {{ gameConfig?.amount }}
        <img src="@/assets/amount_icon.png" alt="金币余额" />
      </div>
      <van-popover
        v-model:show="showPopover"
        theme="dark"
        class="history-popover"
        actions-direction="left-start"
      >
        <ul>
          <li v-for="(item, index) in historyList" :key="index">{{ item }}s</li>
        </ul>
        <p>记录最近30个回合的飞行时间</p>
        <template #reference>
          <div
            @click="getHistoryList"
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #fff;
              font-size: 14px;
            "
          >
            回合历史<img src="@/assets/history_icon.png" alt="金币余额" />
          </div>
        </template>
      </van-popover>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue';
import AviatorGame from '@/components/AviatorGame.vue';
import { showToast } from 'vant';
import axios from 'axios';
import { getQueryParams, decryptData } from '@/utils/tools';

export default defineComponent({
  name: 'HomeView',
  components: {
    AviatorGame,
  },
  setup() {
    const aviatorGame = ref<InstanceType<typeof AviatorGame> | null>(null);

    const state = reactive({
      tabList: [
        { id: 1, name: '游戏介绍' },
        { id: 2, name: '游戏记录' },
      ],
      currIndex: 0,
      showPopover: false,
      historyList: [],
      gameRecordList: [] as any,
      gameConfig: {
        flight_bet_price: '',
        amount: 0,
      },
      gameStatus: 99 as any,
      userToken: '' as string | null,
      userId: '' as string | null,
      source: '' as string | null,
      apiUrl: '',
    });
    const tabsChange = (index: number) => {
      console.log(11);
      state.currIndex = index;
      if (index === 1) {
        getGameBetRecord();
      }
    };

    const { token, user_id, did, source } = getQueryParams(
      'token',
      'user_id',
      'did',
      'source',
    );
    const headers = {
      userid: user_id,
      Token: token,
      deviceid: did,
      source: source,
      'Content-Type': 'application/json',
    };

    const getHistoryList = async () => {
      const url = state.apiUrl + '/api/v2/flightGame/flightGameRecord';
      try {
        const res = await sendRequest(url, headers);
        state.historyList = [];
        state.historyList = res.data.list;
      } catch (error) {
        console.error(error);
      }
    };

    const getGameBetRecord = async () => {
      const url = state.apiUrl + '/api/v2/flightGame/flightRecord';
      try {
        const res = await sendRequest(url, headers);
        state.gameRecordList = res.data.list;
      } catch (error) {
        console.error(error);
      }
    };

    const getGameConfig = async () => {
      const url = state.apiUrl + '/api/v2/flightGame/config';
      try {
        const res = await sendRequest(url, headers);
        state.gameConfig = res.data;
      } catch (error) {
        console.error(error);
      }
    };

    const handleEndGame = async () => {
      const url = state.apiUrl + '/api/v2/flightGame/endGame';
      try {
        const res = await sendRequest(url, headers);

        if (res && res.code === 0) {
          showToast({
            message: res.msg,
            position: 'top',
          });
          await getGameConfig();
        } else {
          showToast({
            message: res.msg,
            position: 'top',
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const starFlight = async () => {
      if (aviatorGame.value) {
        aviatorGame.value.startFlying();
      }
    };

    const handleGameStateUpdate = (status: any) => {
      state.gameStatus = status;
    };

    const sendRequest = async (url: string, headers: object, data = {}) => {
      try {
        const response = await axios.post(url, data, { headers });
        const res_temp = response.data;

        if (res_temp.newcode === '1') {
          return decryptData(
            res_temp.newdata,
            'eDWowITDNUKgND6PfQfA3OMTJ0nm640L',
            '################',
          );
        }
        return res_temp;
      } catch (error) {
        console.error(url);
        throw error;
      }
    };

    onMounted(() => {
      const { token, user_id } = getQueryParams('token', 'user_id', 'did');
      state.userToken = token;
      state.userId = user_id;
      // getGameBetRecord();
      getGameConfig();
    });

    return {
      ...toRefs(state),
      aviatorGame,
      tabsChange,
      getHistoryList,
      handleEndGame,
      starFlight,
      handleGameStateUpdate,
      getGameConfig,
    };
  },
});
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 800px;
  position: relative;
  overflow-x: hidden;
  .text-logo {
    position: absolute;
    top: 90px;
    left: 50%;
    z-index: 1;
    width: 300px;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .aviato-game {
    position: absolute;
    top: 295px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    position: absolute;
    top: 410px;
    img {
      width: 180px;
    }
    .amount {
      position: absolute;
      top: 38px;
      left: 58px;
      font-weight: bold;
      font-size: 14px;
      width: 60px;
    }
  }
  .tab {
    position: absolute;
    top: 530px;
    border: 6px solid transparent;
    border-image: linear-gradient(180deg, #f1d2f7 0%, #f0a7ff 100%) 1;
    border-image-slice: 1;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin: 20px auto;
    padding: 10px;
    width: 88%;
    background-color: #fff;
    z-index: 1;
    min-height: 180px;
    //overflow: auto;

    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      div {
        width: 100px;
        margin: 0 18px;
        height: 28px;
        line-height: 28px;
        background-color: #f6efff;
        color: #8739f3;
        border-radius: 50px;
        font-size: 15px;
      }
      div.active {
        background-color: #8739f3;
        color: #fff;
      }
    }
    .tab-content {
      //height: 500px;
      text-align: left;
      // color: #301365;
      font-weight: bold;
      line-height: 25px;
      font-size: 13px;
    }
    .hb-content {
      border-radius: 16px;
      margin-top: 16px;
      .hb-c-desc {
        text-align: center;

        .hb-c-title {
          //width: 380px;
          font-size: 26px;
          padding-bottom: 15px;
          margin: 0 auto;
          line-height: 35px;
        }

        .attrs {
          display: flex;
          flex-wrap: wrap;

          li {
            color: #4b08b3;
            font-size: 12px;
            display: flex;
            width: 100%;
            .dt {
              flex: 1;
            }

            .dd {
              flex: 1;
            }
          }
          .li1 {
            color: #301365;
            margin-bottom: 10px;
            span {
              font-weight: bold;
              font-size: 14px;
            }
          }
          .li2 {
            background-color: #f7f7f7;
            border-radius: 50px;
            height: 40px;
            line-height: 40px;
            padding: 0 5px;
            margin: 3px 0;
          }
        }
      }
    }
  }
  .amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 20px;
    font-size: 14px;
    position: absolute;
    top: 148px;
    z-index: 1;
    width: 90%;
    div {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        margin-left: 3px;
      }
    }
  }
}
.history-popover {
  width: 90%;
  position: absolute;
  max-width: none;
  transform: translateX(0);
  left: 20px !important;
  ul {
    display: flex;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    padding: 10px 0;
    li {
      width: 47px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background-color: #fff;
      color: #000;
      border-radius: 20px;
      font-size: 12px;
    }
  }
  p {
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
</style>
