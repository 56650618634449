import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'vant/lib/index.css';
import { Toast, Popover, Button } from 'vant';

const app = createApp(App);

app.use(router);
app.use(Toast);
app.use(Popover);
app.use(Button);
app.mount('#app');
